import { Injectable } from '@angular/core'
import { AlertService } from './alert.service'
import { get } from 'lodash'

export type FleetVehicleExcelModeType = 'add' | 'replace'

@Injectable()
export class FleetVehicleExcelUploadService {
  private fleetAccountId: string
  private shouldReplaceVehicleList: string

  constructor(private alertService: AlertService) {}

  setFleetAccountId(fleetAccountId: string): FleetVehicleExcelUploadService {
    this.fleetAccountId = fleetAccountId
    return this
  }

  setMode(mode: FleetVehicleExcelModeType): FleetVehicleExcelUploadService {
    this.shouldReplaceVehicleList = (
      mode.toLowerCase() === 'replace'
    ).toString()
    return this
  }

  getFormDataFromVehicleFile(event: Event): FormData {
    this.validateFleetAccountIdAndMode()

    const element = event.target as HTMLInputElement
    const file = get(element, 'files[0]')

    if (!file) {
      this.alertService.pushAlert({
        type: 'danger',
        message: 'File not found.',
      })
      return
    }

    // check if file is under 5MBs, file.size is in bytes
    if (file.size > 5 * 1024 * 1024) {
      this.alertService.pushAlert({
        type: 'danger',
        message: 'File is too large!',
      })
      return
    }

    const formData = new FormData()

    formData.append('vehicles', file)
    formData.append('fleetAccountId', this.fleetAccountId)
    formData.append('shouldReplaceVehicleList', this.shouldReplaceVehicleList)

    return formData
  }

  private validateFleetAccountIdAndMode() {
    if (!this.fleetAccountId && !this.shouldReplaceVehicleList) {
      throw new Error('You need to set `fleetAccountId` and `mode`!')
    } else if (!this.fleetAccountId) {
      throw new Error('You need to set `fleetAccountId`!')
    } else if (!this.shouldReplaceVehicleList) {
      throw new Error('You need to set `mode`!')
    }
  }
}
